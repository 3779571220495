import { rgb2hex } from "@utils/color";
import { $, body } from "@utils/dom";
import { once } from "@utils/listener";
import { moduleDelays } from "./utils";

const SELECTOR = "[data-site-transition]";

class SiteTransition {
  constructor() {
    this.el = $(SELECTOR);
  }

  exiting() {
    // set transition panel's background-color & color from current page
    const bodyStyles = getComputedStyle(body);
    const bg = bodyStyles.getPropertyValue('--bg-color');
    const color = rgb2hex( bodyStyles.getPropertyValue('color') );
    
    this.el.style.backgroundColor = bg;
    this.el.style.color = color;
  }
  exit() {
    return new Promise(resolve => {
      once(this.el, 'transitionend', resolve);

      this.el.classList.remove("pointer-events-none", "visibility-hidden");
      this.el.classList.add('--js-exit');
    });
  }
  entering() {
    moduleDelays(450, 150);
  }
  enter() {
    return new Promise(resolve => {
      once(this.el, 'transitionend', () => {
        this.el.classList.remove('--js-exit', '--js-enter');
        this.el.classList.add("visibility-hidden", "pointer-events-none");

        resolve();
      });

      this.el.classList.add('--js-enter');
    });
  }
}

export default SiteTransition;
